import * as React from "react";
import { rateList } from "./NewEditOffers.module.scss";
import ListGroup from "react-bootstrap/ListGroup";

export const RateList = (props: any) => {
    return (<ListGroup className={`${rateList} pb-2 ${props.show}`}> {props.children}</ListGroup>);
};



